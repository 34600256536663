import React from 'react';

function Videos() {
    return (
        <>
            <section className="newslater-area p-relative pt-50 pb-50" style={{ background: "#f7f5f1" }}>
                <div className="container">
                    <div className="row">
                        {/* <video autoplay loop src="../../assets/Vid/about.mp4" controls /> */}
                        <video controls autoPlay loop>
                            <source src="../../assets/Vid/about.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Videos