import React from 'react';

function Videos() {
    return (
        <>
            {/* <video autoplay loop src="../../assets/Vid/about.mp4" controls /> */}
            <div style={{ marginTop: 50 }}>
                <video controls autoPlay loop>
                    <source src="../../assets/Vid/JK_GRAND.mp4" type="video/mp4" />
                </video>
            </div>
        </>
    )
}

export default Videos