import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../../Menpages/MenShopDetails/First'

const Main = () => {
    
  return (
    <>
        <main>
            <Bredcom title={"Shop Details"} subtitle={"Home"} newtitle={"Shop Details"}/>
            <First/>    
        </main>
    </>
  )
}

export default Main