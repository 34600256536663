import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Bredcom from '../Bradcom/Main'
import First from '../Menrooms/Menourrooms/First';
import service1 from "../../assets/img/staticImg/xcel-1.jpg"
import service2 from "../../assets/img/staticImg/xcel-29.jpg"
import service3 from "../../assets/img/staticImg/xcel-30.jpg"

const Main = () => {
    const [toggler, setToggler] = useState(false);

    const [img, setImg] = useState()
    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()
    const [img4, setImg4] = useState()
    const [img5, setImg5] = useState()
    const [activeImage, setActiveImage] = useState(1)
    return (
        <>
            <main>
                <Bredcom title={"Rooms"} subtitle={"Home"} newtitle={"Rooms"} />
                <section id="services" className="services-area pt-120 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center', marginBottom: 20 }}>
                                <p>ROOMS AND SERVICES</p>
                                <h3>DISCOVER THE ROOMS WE OFFERED</h3>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <img src={service1} alt="img" />
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>₹7299</li>
                                                <li><Link to="/service">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4>Elite Rooms</h4>

                                        <div style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>
                                            <p><b>Dimention: </b>900 sq.ft</p>
                                            <p><b>Capacity: </b>4 Persons</p>
                                            <p>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <img src={service2} alt="img" />
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>₹6499</li>
                                                <li><Link to="/service">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4>Vivid Rooms</h4>

                                        <div style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>
                                            <p><b>Dimention: </b>900 sq.ft</p>
                                            <p><b>Capacity: </b>4 Persons</p>
                                            <p>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services mb-30">
                                    <div className="services-thumb">
                                        <img src={service3} alt="img" />
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>₹5199</li>
                                                <li><Link to="/service">Book Now</Link></li>
                                            </ul>
                                        </div>
                                        <h4>Urban Rooms</h4>

                                        <div style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>
                                            <p><b>Dimention: </b>350 sq.ft</p>
                                            <p><b>Capacity: </b>2 Persons</p>
                                            <p>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/4.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Decor</li>
                                            </ul>
                                        </div>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>One of the most integral part of the wedding is the décor. The Tamarind Tree is possibly one of the most stunning venues in the world, and in honesty needs the least amount of décor. We know all the nooks and crannies of the property and understand what enhances its beauty and create sets that make the space a wonderland.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/5.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Bridal Services</li>
                                            </ul>
                                        </div>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>Every bride dreams of and anticipates this day for months to come, of the way they look on their special day! We have a list of accomplished beauticians who have worked their magic on many brides of The Tamarind Tree. Trial makeup sessions are arranged post which artists are booked as per the guests choice.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/6.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Photography & Videography</li>
                                            </ul>
                                        </div>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>We understand how important it is to capture these treasured moments that will entitle you to live those moments over and over for a lifetime to come. Various award-winning photography and videography teams have captured many such timeless moments through their lenses. We send across their profiles and organize meetings with the team before our guests can finalize and block their dates.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/7.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Wedding Ceremonies</li>
                                            </ul>
                                        </div>
                                        <h4></h4>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>Suggestions for priests of all customs and traditions can be made along with their bookings. We also procure all the items for the rituals and arrange them as requested by the guests.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/1.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Travel Logistics</li>
                                            </ul>
                                        </div>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>Arrangements for travel to and from the airport to the venue. Shuttle services from hotels to the venue. Weather it’s a Tempo Traveler or an Audi we have travel solutions for all the requests you have.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <img src="../../assets/img/staticImg/9.jpeg" alt="img" />
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li style={{ width: '100%' }}>Music & Entertainment</li>
                                            </ul>
                                        </div>
                                        <p style={{
                                            minHeight: 220,
                                            maxHeight: 220,
                                            overflow: 'auto',
                                        }}>From Nadaswaram & Chenda artists to fusion bands and DJ’s, a wedding has a wide array of requirements in terms of entertainment requirements. We send across the artists portfolios as well sample videos of their art to you. We make booking and manage the artists once their fare is confirmed by you.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Main