import React from 'react'
import { Link } from 'react-router-dom'
import About from "../../assets/img/bg/an-img-02.png"
import About1 from "../../assets/img/staticImg/xcel-21.jpg"
import About2 from "../../assets/img/staticImg/6.jpeg"
import About3 from "../../assets/img/features/signature.png"

function Most() {
    return (
        <>
            <section className="about-area about-p pt-50 pb-50 p-relative fix">
                {/* <div className="animations-02"><img src={About} alt="contact-bg-an-02" /></div> */}
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h5>HOME LIVING REDEFINED</h5>
                                    <h3>Welcome to Xcel Hotel Apartment,</h3>
                                </div>
                                <p>An ideal alternative to the traditional Hotel, "XCEL HOTAL APARTMENT" Serviced Apartments is centrally located and away from hustle and bustle but still well connected to all the major tourist attractions, reputed Restaurants and Malls in Mysore.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={About1} alt="img" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Most