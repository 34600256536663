import React from 'react'
import Bredcom from '../Bradcom/Main'
import { Link } from 'react-router-dom'

const Main = () => {

    return (
        <>
            <main>
                <Bredcom title={"Event Details"} subtitle={"Home"} newtitle={"Event Details"} />
                <section className="shop-banner-area pt-120 pb-90 " data-animation="fadeInUp animated" data-delay=".2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="product-details-img mb-30">
                                    <div className="tab-content" id="myTabContent2">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel">
                                            <div className="product-large-img">
                                                <img src="../../assets/img/staticImg/1.jpeg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="product-details mb-30">
                                    <div className="product-details-title">
                                        <h1>Wedding Stationary</h1>
                                    </div>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page
                                        when looking at its
                                        layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
                                        letters, as opposed to
                                        using 'Content here, content here', making it look like readable English.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Main