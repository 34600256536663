import React from 'react'
import { Link } from 'react-router-dom'
import Resort from "../../assets/img/bg/an-img-02.png"
import Resort1 from "../../assets/img/staticImg/jkgrandarena-9.jpg"

function Luxury() {
    return (
        <>

            <section className="about-area about-p pb-50 p-relative fix">
                {/* <div className="animations-02"><img src={About} alt="contact-bg-an-02" /></div> */}
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h3>Our contemporary convention center boasts modern sophistication</h3>
                                </div>
                                <p>Equipped with state-of-the-art  amenities to host corporate gatherings, conferences, and chic social events. On the other hand, our traditional hall is a timeless treasure, featuring a center courtyard adorned with local heritage motifs. This unique touch adds a cultural richness that sets the stage for grand Indian weddings and cultural celebrations.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <img src={Resort1} alt="img" className="img" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Luxury