import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Blog from "../../assets/img/staticImg/10.jpeg"
import Blog1 from "../../assets/img/blog/inner_b1.jpg"
import Blog2 from "../../assets/img/blog/inner_b2.jpg"
import Blog3 from "../../assets/img/staticImg/xcel-25.jpg"

function Latest() {
    return (
        <>
            <section className="about-area about-p pt-50 pb-50 p-relative fix">
                {/* <div className="animations-02"><img src={About} alt="contact-bg-an-02" /></div> */}
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={Blog3} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h3>Xcel Hotel Apartment Sophistication of Accomodations</h3>
                                </div>
                                <p>Xcel Hotel featured of 10 apartments spread over 4 floors out of which 3 units are of Elite 2Bhk, 4 units are of Vivid 2Bhk, 3units of Urban 1Bhk Apartments and a Rooftop Terrace Lounge which is perfect for Dining, Relaxing and Entertaining and to enjoy a breezy evening with friends and family or soak up some fresh sunshine during the day. Offering hotel luxury accommodation without the constraints of an extended stay in a hotel, they really are the ultimate corporate housing solution.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Latest