import React from 'react'
import Hotel from "../../assets/img/bg/an-img-01.png"
import Booking from "../../assets/img/staticImg/xcel-13.jpg"


function Book() {
    return (
        <>
            <section className="booking pt-50 p-relative fix">
                {/* <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div> */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="contact-bg02">
                                <div className="section-title center-align">
                                    {/* <h5>make appointment</h5> */}
                                    <h2>
                                        Book A Room
                                    </h2>
                                </div>
                                <form action="mail.php" method="post" className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                                <input type="date" id="chackin2" name="date" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                                <input type="date" id="chackout2" name="date" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-users"></i>Apartment Type</label>
                                                <select name="adults" id="adu">
                                                    <option value="sports-massage">Select Apartment Type</option>
                                                    <option value="1">Elite 2 BHK with balcony</option>
                                                    <option value="2">Vivid 2 BHK</option>
                                                    <option value="3">Urban 1 BHK</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-option mb-20">
                                                <label><i className="fal fa-baby"></i>Maximum Persons</label>
                                                <input type="number" placeholder="Enter Maximum Persons" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <div className="slider-btn mt-15">
                                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Confirm Your Booking</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="booking-img">
                                <img src={Booking} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Book