import React from 'react'
import Bredcom from '../Bradcom/Main'
import { Link } from 'react-router-dom'
import "../../custom.css"

const Main = () => {
    return (
        <>
            <main>
                <Bredcom title={"Wedding & Receptions"} subtitle={"Home"} newtitle={"Wedding & Receptions"} />
                <section className="shop-area pt-120 pb-120 p-relative " data-animation="fadeInUp animated" data-delay=".2s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="product mb-40">
                                            <div className="product__img">
                                                <Link to="/shop-details"><img src="../../assets/img/staticImg/1.jpeg" alt="" /></Link>

                                            </div>
                                            <div className="product__content text-center pt-30">
                                                <h5 style={{
                                                    color: '#644222',
                                                    fontSize: 20,
                                                    letterSpacing: 2,
                                                    textTransform: 'uppercase'
                                                }}><Link to="/shop-details">Wedding Stationary</Link></h5>
                                                <p style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="product-action text-center">
                                                    <Link to="/event-full-details">More Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="product mb-40">
                                            <div className="product__img">
                                                <Link to="/shop-details"><img src="../../assets/img/staticImg/2.jpeg" alt="" /></Link>

                                            </div>
                                            <div className="product__content text-center pt-30">
                                                <h5 style={{
                                                    color: '#644222',
                                                    fontSize: 20,
                                                    letterSpacing: 2,
                                                    textTransform: 'uppercase'
                                                }}><Link to="/shop-details">Wedding Budgeting</Link></h5>
                                                <p style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="product-action text-center">
                                                    <Link to="/event-full-details">More Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="product mb-40">
                                            <div className="product__img">
                                                <Link to="/shop-details"><img src="../../assets/img/staticImg/3.jpeg" alt="" /></Link>

                                            </div>
                                            <div className="product__content text-center pt-30">
                                                <h5 style={{
                                                    color: '#644222',
                                                    fontSize: 20,
                                                    letterSpacing: 2,
                                                    textTransform: 'uppercase'
                                                }}><Link to="/shop-details">Food & Beverage</Link></h5>
                                                <p style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="product-action text-center">
                                                    <Link to="/event-full-details">More Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="product mb-40">
                                            <div className="product__img">
                                                <Link to="/shop-details"><img src="../../assets/img/staticImg/2.jpeg" alt="" /></Link>

                                            </div>
                                            <div className="product__content text-center pt-30">
                                                <h5 style={{
                                                    color: '#644222',
                                                    fontSize: 20,
                                                    letterSpacing: 2,
                                                    textTransform: 'uppercase'
                                                }}><Link to="/shop-details">Photography & Videography</Link></h5>
                                                <p style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="product-action text-center">
                                                    <Link to="/event-full-details">More Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="product mb-40">
                                            <div className="product__img">
                                                <Link to="/shop-details"><img src="../../assets/img/staticImg/3.jpeg" alt="" /></Link>

                                            </div>
                                            <div className="product__content text-center pt-30">
                                                <h5 style={{
                                                    color: '#644222',
                                                    fontSize: 20,
                                                    letterSpacing: 2,
                                                    textTransform: 'uppercase'
                                                }}><Link to="/shop-details">Music & Entertainment</Link></h5>
                                                <p style={{ textAlign: 'justify' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="product-action text-center">
                                                    <Link to="/event-full-details">More Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Main