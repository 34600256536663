import React from 'react'
import About from "../../assets/img/staticImg/xcel-8.jpg"

function Skill() {
    return (
        <>
            <section id="skill" className="skill-area p-relative fix" style={{ background: "#423189" }}>
                <div className="animations-01"><img src="assets/img/bg/an-img-05.png" alt="contact-bg-an-05" /></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="skills-content s-about-content">
                                <p>Xcel Hotel Apartment space is designed for today's executives and extended leisure traveller you can relax and enjoy the sophistication of these accommodations while you are on business travel or weekend retreat.</p>
                                <p>It  combines all the comforts of Luxury Beds, attached bathrooms with toiletries, spacious balcony, Contemporary sofa, Modular kitchen with Electric kettle, stove, dining area and all extensive range of facilites. When you choose our Serviced Apartments, you will be staying in a comfortable and economic environment.</p>
                                <p>Whatever be the purpose for your visit to Mysore, you can be assured of a memorable stya with Xcel Hotel Apartment. That's our promise.</p>
                                {/* <div className="skills-content s-about-content mt-20">
                                    <div className="skills">
                                        <div className="skill mb-30">
                                            <div className="skill-name">Qulity Production</div>
                                            <div className="skill-bar">
                                                <div className="skill-per" id="80" style={{ width: "80%" }}></div>
                                            </div>
                                        </div>
                                        <div className="skill mb-30">
                                            <div className="skill-name">Maintenance Services</div>
                                            <div className="skill-bar">
                                                <div className="skill-per" id="90" style={{ width: "90%" }}></div>
                                            </div>
                                        </div>
                                        <div className="skill mb-30">
                                            <div className="skill-name">Product Managment</div>
                                            <div className="skill-bar">
                                                <div className="skill-per" id="70" style={{ width: "70%" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                            <div className="skills-img wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                                <img src={About} alt="img" className="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Skill