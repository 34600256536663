import React, { useState } from 'react'
import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import service1 from "../../assets/img/staticImg/xcel-1.jpg"
import service2 from "../../assets/img/staticImg/xcel-29.jpg"
import service3 from "../../assets/img/staticImg/xcel-30.jpg"
import service4 from "../../assets/img/staticImg/party.jpg"
import service5 from "../../assets/img/staticImg/DJ.jpg"
import service6 from "../../assets/img/staticImg/baby.jpg"
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';


function Events() {
    const service = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <>
            <section id="services" className="services-area pt-113 pb-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="section-title center-align mb-50 text-center">
                                <h5>Rooms & Services</h5>
                                <h2>What we Provide</h2>
                            </div>
                        </div>
                    </div>
                    <Slider className="row services-active" {...service}>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service1} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹7299</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Elite Rooms</h4>

                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>900 sq.ft</p>
                                        <p><b>Capacity: </b>4 Persons</p>
                                        <p>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service2} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹6499</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Vivid Rooms</h4>

                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>900 sq.ft</p>
                                        <p><b>Capacity: </b>4 Persons</p>
                                        <p>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service3} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹5199</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Urban Rooms</h4>

                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>350 sq.ft</p>
                                        <p><b>Capacity: </b>2 Persons</p>
                                        <p>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service4} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹1000</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Party</h4>

                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>5000 sq.ft</p>
                                        <p><b>Capacity: </b>600 Persons</p>
                                        <p>Want to set the perfect mood for a fun Party and will ensure a lively, energetic look that matches your vibe with the most Trendy Arrangements and Lights.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service6} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹50000</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Baby Shower</h4>
                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>1000 sq.ft</p>
                                        <p><b>Capacity: </b>500 Persons</p>
                                        <p>We want dear moms-to-be and their families to have a warm, loving and fun day. Our professional Helpers and Co-ordinators, Ambient Decors and Lights are sure to let you be at ease through the event. Let our customised services make this day special for you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
                                    <img src={service5} alt="img" />
                                </div>
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹35000</li>
                                            <li><Link to="/service">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>DJ Sound</h4>
                                    <div style={{
                                        minHeight: 220,
                                        maxHeight: 220,
                                        overflow: 'auto',
                                    }}>
                                        <p><b>Dimention: </b>5000 sq.ft</p>
                                        <p><b>Capacity: </b>400 Persons</p>
                                        <p>An amazing event should have amazing music. We provide the finest DJs and Sound Systems to set the mood and get those feet moving!.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Events