import { Link } from 'react-router-dom'
import React, { useState, useRef } from 'react'
import Slider from "react-slick"
import Image1 from "../../assets/img/staticImg/1.jpeg"
import Image2 from "../../assets/img/staticImg/2.jpeg"
import Image3 from "../../assets/img/staticImg/3.jpg"


function Videos() {
    return (
        <>

            <section id="services" className="services-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: 'center', marginBottom: 20 }}>

                            <h3>TOUR BLOG</h3>
                        </div>
                        <div className="col-md-12">
                            <div style={{ marginTop: 50 }}>
                                {/* <video controls autoPlay loop>
                                    <source src="../../assets/Vid/JK_GRAND.mp4" type="video/mp4" />
                                </video> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <video autoplay loop src="../../assets/Vid/about.mp4" controls /> */}

        </>
    )
}

export default Videos