import { Link } from 'react-router-dom'
import React from 'react'
import BGImg from "../../assets/img/bg/bdrc-bg.jpg";
import Bradcom from '../Bradcom/Main'
import Most from '../Menhome/Most';
import Skill from '../Menabout/Skill';
import Luxury from '../Menhome/Luxury';
import Latest from '../Menhome/Latest';
import News from '../Menabout/News';
import Confirts from '../Menabout/Confirts';
import Videos from '../Menabout/Videos';

const Main = () => {
  return (
    <>
        <main>
            <Bradcom title={"About Us"} subtitle={"Home"} newtitle={"About Us"}/>
            <Most/>
            {/* <Videos/> */}
            <Latest/>
            <Skill/>
            
            {/* <Luxury/> */}
            {/* <Confirts/> */}
            <News/>
        </main>
    </>
  )
}

export default Main