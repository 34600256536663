import React from 'react'
import Bradcom from '../../Bradcom/Second'
import Teem from '../../Menpages/Menteemdeatils/Teem'


const Main = () => {
  return (
    <>
        <main>
            <Bradcom title={"Team Details"} subtitle={"Home"} newtitle={"Team Details"}/>
            <Teem/>
        </main>
    </>
  )
}

export default Main